import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import NRStatus from '../NRStatus/NRStatus';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import { getDataFromComposedAssetId, isEmpty } from '../../utils/utils';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CONSTANTS } from '../../constants/constants';
import moment from 'moment';
import NRAnalysisLogStyles from './NRAnalysisLog.styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import NRLoader from '../NRLoader/NRLoader';
import { ComponentContext } from '../../ContextWrappers/NRComponentContextWrapper';
import useNRLazyQuery from '../../graphql/useNRLazyQuery';
import { GET_ASSET } from '../../graphql/queries/asset';

SyntaxHighlighter.registerLanguage('bash', bash);
const { CLOSE } = CONSTANTS.ACTIONS;
const { ANALYSIS_LOG, ANALYSIS_LOG_THRESHOLDS } = CONSTANTS.DATE_FORMAT;
const { IN_PROGRESS, COMPLETED, FAILED } = CONSTANTS.TABLE.ASSETS.STATUS;

function parsedAnalysisStatus(orgComponent) {
  function addSuccessfullyRan() {
    return `Analysis Successfully ran.${addDuration(orgComponent.createdAt, orgComponent.earliestSnapshotEndTime, true)}`;
  }

  switch (orgComponent.status) {
    case COMPLETED:
      return addSuccessfullyRan();

    case IN_PROGRESS:
      if (!!orgComponent.earliestSnapshotEndTime) {
        return addSuccessfullyRan();
      } else return `[ Analysis still running ] (started about ${moment(new Date(orgComponent.startTime || orgComponent.createdAt)).fromNow()}),`;

    default:
      //FAILED
      let toReturn = '';
      if (!!orgComponent.earliestSnapshotEndTime) {
        toReturn = addSuccessfullyRan();
        toReturn += `Analysis updated at: ${moment(new Date(orgComponent.startTime))?.format(ANALYSIS_LOG)},`;
      }
      toReturn += !!orgComponent.endTime
        ? `Analysis ${orgComponent.status === FAILED ? 'failed' : orgComponent.status} at: ${moment(new Date(orgComponent.endTime))?.format(
            ANALYSIS_LOG
          )},` + `Details: FAIL`
        : 'Details: FAIL';
      return toReturn;
  }
}

function addDuration(startTime, endTime, newLineWhenNoDuration) {
  if (!startTime || !endTime) return !!newLineWhenNoDuration ? ',' : '';
  const duration = moment.duration(moment(new Date(endTime || Date.now())).diff(new Date(startTime))).humanize();
  return ` Duration: about ${duration},`;
}

const useStyles = makeStyles(NRAnalysisLogStyles);

function NRAnalysisLog({ componentId, open, handleClose }) {
  const theme = useTheme();
  const classes = useStyles();
  const auth0Namespace = process.env.REACT_APP_AUTH0_NAMESPACE;
  const orgNamespace = auth0Namespace + 'org_name';
  const [org, setOrg] = useState(null);
  const [component, setComponent] = useState(null);
  const [parsedAnalysis, setParsedAnalysis] = useState({});
  const { getIdTokenClaims } = useAuth0();
  const { asset } = useContext(ComponentContext);

  const [assetData, , called, makeRequest] = useNRLazyQuery({
    query: GET_ASSET,
    options: { context: { version: 'v3' }, fetchPolicy: 'network-only' },
    qlObjKey: 'asset'
  });

  async function setOrgInfo(asset) {
    let idToken = await getIdTokenClaims({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    });
    setOrg(idToken[orgNamespace]);
    setComponent(asset);
  }

  useEffect(() => {
    if (!!component) {
      parseJSON(component);
    }
  }, [component, org]);

  useEffect(() => {
    if (asset) {
      setOrgInfo(asset);
    } else if (!asset && !called && !assetData) {
      makeRequest({ variables: { args: { assetId: componentId } } });
    }
  }, []);

  useEffect(() => {
    if (assetData && !isEmpty(assetData)) {
      setOrgInfo(assetData);
    }
  }, [assetData]);

  function parseJSON(component) {
    let parsed = '';
    let { assetId, projectId, runId, snapshotId } = !!component.componentId
      ? getDataFromComposedAssetId(component.componentId)
      : getDataFromComposedAssetId(componentId);
    parsed = parsed.concat(
      `Asset Name: ${component.name || assetId},`,
      `Asset ID: ${assetId},`,
      `Project ID: ${projectId},`,
      `Run ID: ${runId},`,
      `Snapshot ID: ${snapshotId},`,
      !!org ? `Org: ${org},` : '',
      !!component.createdAt
        ? `${component.status === IN_PROGRESS && !!component.earliestSnapshotEndTime ? 'Initial ' : ''}Analysis started at: ${moment(
            new Date(component.createdAt)
          )?.format(ANALYSIS_LOG)},`
        : '',
      !!component.earliestSnapshotEndTime
        ? `${component.status === IN_PROGRESS ? 'Initial ' : ''}Analysis completed at: ${moment(new Date(component.earliestSnapshotEndTime))?.format(
            ANALYSIS_LOG
          )},`
        : '',
      component.status === COMPLETED || (component.status === FAILED && !!component.earliestSnapshotEndTime) ? 'All Tasks Completed Successfully.,' : '',
      parsedAnalysisStatus(component),
      (component.status === IN_PROGRESS) && !!component.earliestSnapshotEndTime
        ? `Analysis updated at: ${moment(new Date(component.startTime))?.format(ANALYSIS_LOG)}`
        : '',
      (component.status === COMPLETED) && !!component.earliestSnapshotEndTime
        ? `Analysis updated at: ${moment(new Date(component.endTime))?.format(ANALYSIS_LOG)}`
        : '',
      component.status === IN_PROGRESS && !!component.earliestSnapshotEndTime
        ? `,[ Analysis is updating ] (started ${moment(new Date(component.updatedAt)).fromNow()} )`
        : ''
    );
    setParsedAnalysis(parsed.replace(/ *, */g, '\n'));
  }

  const body = (
    <>
      {!!component ? (
        <Card classes={{ root: classes.card }} className={classes.cardPosition} elevation={0}>
          <CardHeader
            classes={{ root: classes.cardHeader, action: classes.action }}
            title={
              <div className={classes.titleContainer}>
                <Typography component="span" variant="h3">{`Analysis Log`}</Typography>
                <NRStatus fontSize={12} statusValue={component.status} style={{ marginRight: '12px' }} />
              </div>
            }
            action={
              <Tooltip title={CLOSE} placement="right">
                <IconButton aria-label="Close" onClick={handleClose}>
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          />
          <CardContent classes={{ root: classes.cardSection }}>
            {!!component && (
              <>
                {parsedAnalysis && !isEmpty(parsedAnalysis) && (
                  <SyntaxHighlighter
                    customStyle={{ maxHeight: '60vh', margin: '16px 0', backgroundColor: theme.palette.neutral[800] }}
                    showLineNumbers={true}
                    wrapLongLines={true}
                    language="text"
                    style={a11yDark}
                  >
                    {parsedAnalysis}
                  </SyntaxHighlighter>
                )}
              </>
            )}
          </CardContent>
          <CardActions classes={{ root: classes.cardActions }}>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              {CLOSE}
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Box sx={{ display: 'flex', height: '-webkit-fill-available', justifyContent: 'center', alignItems: 'center' }}>
          <NRLoader />
        </Box>
      )}
    </>
  );

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="analysis-log-title" aria-describedby="analysis-log-description">
      {body}
    </Modal>
  );
}
export default NRAnalysisLog;
