import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CopyToClipboardBannerStyles from './CopyToClipboardBanner.styles';
import Copy from 'react-feather/dist/icons/copy';
import X from 'react-feather/dist/icons/x';
import { copyAsCSV, getAssetIdFromPath } from '../../../utils/utils';
import { CONSTANTS } from '../../../constants/constants';
import NRRemediateIcon from '../../../NRIcons/NRRemediateIcon';
import { useContext, useEffect, useState } from 'react';
import { ReportTabsContext } from '../../../pages/NRReportTabs/NRReportTabsContextWrapper';
import { RemediationContext } from '../../NRRemediation/NRRemediationContext';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridContext } from '../../NRReactGrid/NRReactGridContext';
import { useSnackbar } from 'notistack';
import FileDownloadComponent from '../../NRCertTable/CertTableCustomComponents/FileDownloadComponent';
const { ITEM_SELECTED, ITEMS_SELECTED, TOOLTIP_COPY, TOOLTIP_UNSELECT, TOOLTIP_REMEDIATE, TOOLTIP_DOWNLOAD } =
  CONSTANTS.TABLE.COPY_TO_CLIPBOARD_BANNER;
const { VULNERABILITIES: VULNERABILITIES_TAB, FILE_SYSTEM: FILE_SYSTEM_TAB } = CONSTANTS.REPORT.TABS;

const useStyles = makeStyles(CopyToClipboardBannerStyles); // add to constants

function CopyToClipboardBanner({ selectedItems, setSelection, rows }) {
  const { currentTab } = useContext(ReportTabsContext);
  const { setOpenRemediationModal, setCVEs } = useContext(RemediationContext);
  const { setShouldClearSelection } = useContext(GridContext);
  const { enqueueSnackbar } = useSnackbar();
  const [filesToDownload, setFilesToDownload] = useState();
  const [disableDownload, setDisableDownload] = useState(false);
  const theme = useTheme();

  const classes = useStyles();

  const handleRemediationClick = () => {
    setCVEs(selectedItems.map(item => rows[item]));
    setOpenRemediationModal(true);
  };

  let assetId = getAssetIdFromPath();

  useEffect(() => {
    return () => setFilesToDownload(null);
  }, []);

  useEffect(() => {
    if (currentTab === FILE_SYSTEM_TAB) {
      handleBulkDownload();
    }
  }, [selectedItems]);

  const handleBulkDownload = () => {
    let toReturn = selectedItems.map(item => ({ path: rows[item]?.path }));
    setDisableDownload(selectedItems.some(item => !rows[item]?.canDownload));
    setFilesToDownload(toReturn);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.title}>
        {selectedItems.length + ' '}
        {selectedItems.length > 1 ? ITEMS_SELECTED : ITEM_SELECTED}
      </div>
      <div className={classes.actions}>
        {currentTab === VULNERABILITIES_TAB && (
          <>
            <Tooltip title={TOOLTIP_REMEDIATE}>
              <IconButton aria-label="Remediate items" component="span" color="inherit" size="small" onClick={handleRemediationClick}>
                <NRRemediateIcon color={theme.palette.secondary.contrastText} />
                <Typography variant={'subtitle2'} color={theme.palette.secondary.contrastText} sx={{ ml: 1, mr: 1 }}>
                  Remediate
                </Typography>
              </IconButton>
            </Tooltip>
          </>
        )}
        {currentTab === FILE_SYSTEM_TAB && (
          <>
            <FileDownloadComponent
              componentId={assetId}
              fileList={filesToDownload}
              cursorPointer={true}
              tooltipTitle={TOOLTIP_DOWNLOAD}
              color={theme.palette.secondary.contrastText}
              showButtonComponent={true}
              afterDownloadAction={() => setShouldClearSelection(true)}
              disabled={disableDownload}
            />
          </>
        )}
        <Tooltip title={TOOLTIP_COPY}>
          <IconButton
            aria-label="copy to csv"
            component="span"
            color="inherit"
            size="small"
            onClick={() => {
              copyAsCSV(selectedItems.map(item => rows[item]));
              enqueueSnackbar(CONSTANTS.COPIED_TO_CLIPBOARD_MSG, { variant: 'success' });
              setShouldClearSelection(true);
            }}
            classes={{ colorInherit: classes.actions }}
          >
            <Copy />
            <Typography variant={'subtitle2'} color={theme.palette.secondary.contrastText} sx={{ ml: 1, mr: 1 }}>
              Copy to CSV
            </Typography>
          </IconButton>
        </Tooltip>
        <Tooltip title={TOOLTIP_UNSELECT}>
          <IconButton
            aria-label="unselect"
            component="span"
            color="inherit"
            size="small"
            onClick={() => setSelection([])}
            classes={{ colorInherit: classes.actions }}
          >
            <X />
            <Typography variant={'subtitle2'} color={theme.palette.secondary.contrastText} sx={{ ml: 1 }}>
              Cancel
            </Typography>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
export default CopyToClipboardBanner;
